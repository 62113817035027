import React from "react";
import SectionEditForm from "../domain/EditForms/SectionEditForm";
import ItemEditScreen from "../templates/ItemEditScreen";
import View from "../templates/View";

const SectionEditView: React.FC = ({ children, ...props }) => {
	return (
		<View title="Edit Section">
			<ItemEditScreen itemType="section">
				<SectionEditForm />
			</ItemEditScreen>
		</View>
	);
};

export default SectionEditView;

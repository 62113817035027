import React from "react";
import {
	useDefaultEditState,
	useEditForm,
} from "../../templates/ItemEditScreen";
import ItemEditTitleField from "../../templates/ItemEditTitleField";
import {
	Button,
	Center,
	Flex,
	Heading,
	IconButton,
	IconButtonProps,
	Text,
	Textarea,
} from "@chakra-ui/react";
import ItemEditInputField from "../../templates/ItemEditInputField";
import { MinusIcon } from "@chakra-ui/icons";
import { updateIndexedAccessTypeNode } from "typescript";

const PortfolioItemEditForm: React.FC = (props) => {
	const { editState, setEditState } = useEditForm();
	useDefaultEditState({
		name: "New Portfolio Item",
		description: "Description",
		images: [],
		tags: [],
		git_link: "",
		live_link: "",
	});
	const { name, description, images, tags, live_link, git_link } = editState;

	return (
		<>
			<ItemEditTitleField
				value={name}
				onChange={(e) => setEditState({ name: e.target.value })}
			/>
			<ItemEditInputField
				as={Textarea}
				label="Description"
				value={description}
				onChange={(e) => setEditState({ description: e.target.value })}
			/>
			<FormSubHeader>Images</FormSubHeader>
			{images && images.length ? (
				images.map((item: string, index: number) => (
					<Flex w="100%" key={`image-field-${index}`} align="center">
						<ItemEditInputField
							value={images[index]}
							onChange={(e) => {
								editState.images[index] = e.target.value;
								setEditState({ images: editState.images });
							}}
						/>
						<DeleteFieldButton
							aria-label="delete-image-field"
							onClick={() => {
								if (window.confirm(`Delete image '${item}' ?`)) {
									editState.images.splice(index, 1);
									setEditState({ images: editState.images });
								}
							}}
						/>
					</Flex>
				))
			) : (
				<Text>No Images</Text>
			)}
			<Button
				onClick={() => setEditState({ images: [...editState.images, ""] })}
			>
				New Image
			</Button>
			<FormSubHeader>Tags</FormSubHeader>
			{tags && tags.length ? (
				tags.map((item: string, index: number) => (
					<Flex
						w="100%"
						key={`tag-field-${updateIndexedAccessTypeNode}`}
						align="center"
					>
						<ItemEditInputField
							value={tags[index]}
							onChange={(e) => {
								editState.tags[index] = e.target.value;
								setEditState({ tags: editState.tags });
							}}
						/>
						<DeleteFieldButton
							aria-label="delete-tag-field"
							onClick={() => {
								if (window.confirm(`Delete tag '${item}' ?`)) {
									editState.tags.splice(index, 1);
									setEditState({ tags: editState.tags });
								}
							}}
						/>
					</Flex>
				))
			) : (
				<Text>No Tags</Text>
			)}
			<Button onClick={() => setEditState({ tags: [...editState.tags, ""] })}>
				New Tag
			</Button>
			<FormSubHeader>Links</FormSubHeader>
			<ItemEditInputField
				label="Live Demo Link"
				value={live_link}
				onChange={(e) => setEditState({ live_link: e.target.value })}
			/>
			<ItemEditInputField
				label="Github Link`"
				value={git_link}
				onChange={(e) => setEditState({ git_link: e.target.value })}
			/>
		</>
	);
};

const FormSubHeader: React.FC = ({ children }) => (
	<Heading as="h4" size="md">
		{children}
	</Heading>
);

const DeleteFieldButton: React.FC<IconButtonProps> = ({ ...props }) => (
	<Center h="100%" flexGrow={1}>
		<IconButton
			icon={<MinusIcon />}
			borderRadius="full"
			colorScheme="red"
			marginLeft={1}
			size="sm"
			{...props}
		/>
	</Center>
);

export default PortfolioItemEditForm;

import React from "react";
import View from "../templates/View";
import ResumeItemList from "../templates/ResumeItemList";
import ServiceController from "../../utils/ServiceController";
import { SkillProps } from "tc-resume-common";

const SkillServiceController = new ServiceController<SkillProps>("/skills");

const SkillsView: React.FC = () => {
	return (
		<View title="Skills">
			<ResumeItemList
				itemType="skills"
				itemsTitleProp="name"
				itemsBodyProp="level"
				controller={SkillServiceController}
			/>
		</View>
	);
};

export default SkillsView;

import React from "react";
import View from "../templates/View";
import ResumeItemList from "../templates/ResumeItemList";
import ServiceController from "../../utils/ServiceController";
import { SectionProps } from "tc-resume-common";

const SectionServiceController = new ServiceController<SectionProps>(
	"/sections",
);

const SectionsView: React.FC = () => {
	return (
		<View title="Sections">
			<ResumeItemList
				itemType="sections"
				itemsTitleProp="name"
				itemsBodyProp="body"
				controller={SectionServiceController}
			/>
		</View>
	);
};

export default SectionsView;

import { InputProps } from "@chakra-ui/react";
import React from "react";
import { itemTitleMaxLength } from "tc-resume-common";
import ItemEditInputField from "./ItemEditInputField";

const ItemEditTitleField: React.FC<InputProps> = ({ children, ...props }) => {
	return (
		<ItemEditInputField
			label="Title"
			maxLength={itemTitleMaxLength}
			{...props}
		/>
	);
};

export default ItemEditTitleField;

import axios from "axios";
import { BACKEND_URL } from "./env";


const axiosInstance = axios.create({
	baseURL:BACKEND_URL
})

axiosInstance.interceptors.request.use(req => {
	req.headers.access_key = localStorage.getItem("access_key") || "";
	return req;
})


export {axiosInstance}
import { ServerResponseFields } from "tc-resume-common";
import { axiosInstance } from "../config/axios.config";

/**
 * Class for managing interactions with api
 */
class ServiceController<T> {
	public fetchAll:any;
	public fetchOne:any;
	public deleteOne:any;
	public create:any;
	public update:any;

	/**
	 * Create a new Service Controller
	 * @param {string} baseUrl The base url for making requests too
	 */
	constructor(baseUrl:string) {
		//? Methods have to be defined in constructor due to annoying 'this'
		//? binding behaviour that occurs when using 'react-query'

		this.fetchAll = () => axiosInstance.get<ServerResponseFields<T[]>>(baseUrl).then(res => res.data.extraData);
		this.fetchOne = (_id:string) => axiosInstance.get<ServerResponseFields<T>>(`${baseUrl}/${_id}`).then(res => res.data.extraData);
		this.deleteOne = (target:T) => axiosInstance.delete<ServerResponseFields<T[]>>(baseUrl,{data:target});
		this.create = (newItem:T) => axiosInstance.post<ServerResponseFields<T>>(baseUrl, newItem);
		this.update = (target:T) => axiosInstance.put<ServerResponseFields<T>>(baseUrl, target);
	}

}

export default ServiceController
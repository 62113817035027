import { Box, Button, Flex, FlexProps, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { listSpacing } from "./ResumeItemList";

interface Props extends FlexProps {
	_id: string;
	title: string;
	body?: string | number;
	onDelete: any;
	itemType: string;
}

const ResumeItem: React.FC<Props> = ({
	itemType,
	onDelete,
	body,
	title,
	_id,
	...props
}) => {
	const queryClient = useQueryClient();
	const deleteFn = useMutation(onDelete, {
		onSuccess: () => {
			queryClient.invalidateQueries(itemType);
		},
	});

	return (
		<Flex
			justify="space-between"
			align="center"
			backgroundColor="white"
			padding={listSpacing}
			width="100%"
			boxShadow="base"
			{...props}
		>
			<Box flexShrink={1} paddingRight={4}>
				<Heading as="h3" size="lg">
					{title}
				</Heading>
				<Text>{body}</Text>
			</Box>
			<Box flexShrink={0}>
				<Button
					marginRight={2}
					colorScheme="blue"
					as={Link}
					to={`/edit/${itemType}/${_id}`}
				>
					EDIT
				</Button>
				<Button
					colorScheme="red"
					onClick={() => {
						if (window.confirm(`Delete item '${title}'?`)) {
							deleteFn.mutate();
						}
					}}
				>
					DELETE
				</Button>
			</Box>
		</Flex>
	);
};

export default ResumeItem;

import { Box, FormLabel, Input, InputProps } from "@chakra-ui/react";
import React from "react";

interface Props extends InputProps {
	label?: string;
}

const ItemEditInputField: React.FC<Props> = ({ label, children, ...props }) => {
	return (
		<Box width="100%">
			{label && <FormLabel>{label}</FormLabel>}
			<Input {...props} />
		</Box>
	);
};

export default ItemEditInputField;

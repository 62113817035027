import {
	Box,
	BoxProps,
	Button,
	Flex,
	Heading,
	SimpleGrid,
	Text,
} from "@chakra-ui/react";
import React from "react";
import ServiceController from "../../utils/ServiceController";
import FailedFetchMessage from "./FailedFetchMessage";
import ResumeItem from "./ResumeItem";
import { titleCase } from "title-case";
import { useQuery } from "react-query";
import { AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

/**
 * @typedef Props props for this component
 * @property {any[]} items The items to be displayed in the list
 * @property {string} itemType The name of the type of items being fetched.
 * Affects the title text of the list as well as the keys passed to each item.
 * @property {string} itemsTitleProp The field from which to pull the 'title'
 * value for each item.
 * @property {string} itemsBodyProp The field from which to pull the 'body'
 * value for each item.
 * @property {ServiceController<any>} controller The controller used to interact with api data
 * @property {string} [title=titleCase(itemType)] The title to show at the top of the list. If
 * not provided, defaults to the provided 'itemType' string with title case formatting applied.
 */
interface Props extends BoxProps {
	itemType: string;
	itemsTitleProp: string;
	itemsBodyProp: string;
	controller: ServiceController<any>;
	title?: string;
	columns?: number | number[];
}

export const listSpacing = 4;

//#Template component for list of resume items
const ResumeItemList: React.FC<Props> = ({
	itemType,
	itemsTitleProp,
	itemsBodyProp,
	controller,
	title = titleCase(itemType),
	columns = 1,
	...props
}) => {
	const { data, isLoading, error } = useQuery(itemType, controller.fetchAll);
	const items = data || [];

	const getItemOnDelete = (item: any) => () => {
		controller.deleteOne(item);
	};

	return (
		<Box paddingX={listSpacing} width="100%" {...props}>
			<Flex padding={4} justify="space-between" align="center">
				<Heading marginRight={20}>{title}</Heading>
				<Button
					size="md"
					borderRadius="full"
					aria-label="create-new-item"
					colorScheme="teal"
					variant="outline"
					rightIcon={<AddIcon />}
					as={Link}
					to={`/edit/${itemType}/new`}
				>
					Create New
				</Button>
			</Flex>
			{isLoading && <Text>Loading {itemType}...</Text>}{" "}
			{error && !isLoading && !data && <FailedFetchMessage target={itemType} />}
			{data && !isLoading && (
				<SimpleGrid columns={columns} spacing={listSpacing}>
					{(items as any).map((item: any, index: number) => (
						<ResumeItem
							title={item[itemsTitleProp]}
							body={item[itemsBodyProp]}
							key={`${itemType}-list-item-${index}`}
							onDelete={getItemOnDelete(item)}
							itemType={itemType}
							_id={item._id}
						/>
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};

export default ResumeItemList;

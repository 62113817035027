import React from "react";
import SkillEditForm from "../domain/EditForms/SkillEditForm";
import ItemEditScreen from "../templates/ItemEditScreen";
import View from "../templates/View";

const SkillEditView: React.FC = ({ children, ...props }) => {
	return (
		<View title="Edit Skill">
			<ItemEditScreen itemType="skill">
				<SkillEditForm />
			</ItemEditScreen>
		</View>
	);
};

export default SkillEditView;

import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertProps,
	AlertTitle,
} from "@chakra-ui/react";
import React from "react";

interface Props extends AlertProps {
	target: string;
}

//# Error message for when an error occurs during data fetching
const FailedFetchMessage: React.FC<Props> = ({ target, ...props }) => {
	return (
		<Alert {...props} status="error">
			<AlertIcon />
			<AlertTitle>Error</AlertTitle>
			<AlertDescription>Failed to load {target}</AlertDescription>
		</Alert>
	);
};

export default FailedFetchMessage;

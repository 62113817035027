import {
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
} from "@chakra-ui/react";
import React from "react";
import { maxSkillValue, itemTitleMaxLength } from "tc-resume-common";
import {
	useDefaultEditState,
	useEditForm,
} from "../../templates/ItemEditScreen";

const SkillEditForm: React.FC = ({ ...props }) => {
	const { editState, setEditState } = useEditForm();
	useDefaultEditState({
		name: "New Item",
		level: 1,
	});
	const onLevelChange = (newValue: string) => {
		const parsedNewValue = parseInt(newValue);
		if (parsedNewValue === 1 || parsedNewValue === 2 || parsedNewValue === 3)
			setEditState({ level: parsedNewValue });
	};

	return (
		<>
			<Input
				value={editState.name}
				onChange={(e) => setEditState({ name: e.target.value })}
				maxLength={itemTitleMaxLength}
			/>
			<NumberInput
				value={editState.level}
				min={1}
				max={maxSkillValue}
				onChange={onLevelChange}
				width="100%"
			>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		</>
	);
};

export default SkillEditForm;

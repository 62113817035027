import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertStatus,
	AlertTitle,
	Box,
	Button,
	Center,
	CircularProgress,
	Flex,
	Heading,
	Link,
	Text,
	VStack,
} from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useMount, useSetState, useUnmount } from "react-use";
import { ServerResponseFields } from "tc-resume-common";
import { titleCase } from "title-case";
import ServiceController from "../../utils/ServiceController";
import FailedFetchMessage from "./FailedFetchMessage";
import { Link as RouterLink } from "react-router-dom";

interface Props {
	itemType: string;
	baseUrl?: string;
	listUrl?: string;
}

const ItemEditContext = createContext<{
	editState: any;
	setEditState: any;
	isNewItem: boolean;
}>({
	editState: {},
	setEditState: () => {},
	isNewItem: false,
});

export const useEditForm = () => useContext(ItemEditContext);

export const useDefaultEditState = (defaultState: any) => {
	const { isNewItem, setEditState } = useEditForm();
	useMount(() => {
		if (isNewItem) setEditState(defaultState);
	});
};

const ItemEditScreen: React.FC<Props> = ({
	itemType,
	baseUrl = `/${itemType}s`,
	listUrl = baseUrl,
	children,
	...props
}) => {
	const { _id } = useParams<{ _id: string }>();
	const isNewItem = _id === "new";
	const serviceController = new ServiceController(baseUrl);

	const { isLoading, error, data, remove } = useQuery(
		`editing-${itemType}`,
		() => serviceController.fetchOne(_id),
	);

	const [editState, setEditState] = useSetState<any>({});

	useEffect(() => {
		console.log("(ItemEditScreen) data: ", data);
		if (data && !isNewItem) {
			setEditState(data);
		}
	}, [data, setEditState, isNewItem]);

	useUnmount(remove);

	const [response, setResponse] = useState<{
		status: AlertStatus;
		title: string;
		description: string;
	} | null>(null);

	const queryClient = useQueryClient();
	const { mutate: submit, status } = useMutation<
		any,
		ServerResponseFields<any>,
		any
	>(isNewItem ? serviceController.create : serviceController.update, {
		onSuccess: () => {
			queryClient.invalidateQueries(itemType);
			setResponse({
				status: "success",
				title: "Submitted",
				description: "Submission was successful",
			});
		},
		onError: () => {
			setResponse({
				status: "error",
				title: "Error",
				description: "There was an error while submitting",
			});
		},
	});

	const onSubmit = () => {
		submit(editState);
		setResponse({ title: "Loading", description: "", status: "info" });
	};

	return (
		<ItemEditContext.Provider value={{ editState, setEditState, isNewItem }}>
			<Center {...props}>
				<Box width="95%" maxWidth="600px">
					{response && (
						<Alert marginTop={2} status={response?.status}>
							<Flex justify="space-between" width="100%">
								<Flex>
									<AlertIcon />
									<AlertTitle mr={2}>{response?.title}</AlertTitle>
									<AlertDescription>{response?.description} </AlertDescription>
								</Flex>
								<Link as={RouterLink} to={listUrl} textDecoration="underline">
									view all {itemType}s
								</Link>
							</Flex>
						</Alert>
					)}
					{isLoading && !isNewItem && (
						<Center>
							<Box>
								<Text>Loading...</Text>
								<CircularProgress isIndeterminate={true} />
							</Box>
						</Center>
					)}
					{error && !isNewItem && !isLoading && (
						<FailedFetchMessage target={itemType} />
					)}
					{((data && !isLoading) || isNewItem) && (
						<Box
							marginTop={2}
							backgroundColor="white"
							boxShadow="base"
							borderRadius="base"
							padding={4}
						>
							<Heading>
								{isNewItem ? "New" : "Edit"} {titleCase(itemType)}
							</Heading>
							<Flex marginY={1}>
								<Text fontWeight="bold" marginRight={1}>
									Id:
								</Text>
								<Text>{_id}</Text>
							</Flex>
							<VStack spacing={2}>
								{children}
								<Button
									colorScheme="blue"
									isLoading={status === "loading"}
									onClick={onSubmit}
								>
									Submit
								</Button>
							</VStack>
						</Box>
					)}
				</Box>
			</Center>
		</ItemEditContext.Provider>
	);
};

export default ItemEditScreen;

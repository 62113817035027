import React from "react";
import View from "../templates/View";
import ResumeItemList from "../templates/ResumeItemList";
import ServiceController from "../../utils/ServiceController";
import { PortfolioItemProps } from "tc-resume-common";

const PortfolioServiceController = new ServiceController<PortfolioItemProps>(
	"/portfolio",
);

const PortfolioView: React.FC = () => {
	return (
		<View title="Portfolio">
			<ResumeItemList
				itemType="portfolio"
				itemsTitleProp="name"
				itemsBodyProp="description"
				controller={PortfolioServiceController}
			/>
		</View>
	);
};

export default PortfolioView;

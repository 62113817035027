import {
	Box,
	Button,
	ButtonProps,
	Flex,
	FlexProps,
	HStack,
} from "@chakra-ui/react";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useMount } from "react-use";
import { accessKeyStorageKey } from "../../constants/keys";

interface Props {
	title?: string;
}

const baseTitle = "TC Resume Admin Panel";

const TopBarLink: React.FC<LinkProps & ButtonProps> = ({
	children,
	...props
}) => (
	<Button
		as={Link}
		variant="ghost"
		colorScheme="whiteAlpha"
		color="white"
		paddingX={{ sm: 2, lg: 4 }}
		{...props}
	>
		{children}
	</Button>
);

const TopBarSpacer: React.FC<FlexProps> = ({ ...props }) => (
	<Flex width={96} {...props} justify="flex-end" />
);

const View: React.FC<Props> = ({ title, children, ...props }) => {
	useMount(() => {
		document.title = title ? `${title} - ${baseTitle}` : baseTitle;
	});
	return (
		<Box width="100%">
			<Flex
				width="100%"
				height={20}
				backgroundColor="teal.500"
				justify="space-between"
				align="center"
				paddingX={4}
			>
				<TopBarSpacer display={["none", "inherit"]} />
				<HStack spacing={[2, 4]}>
					<TopBarLink to="/skills">Skills</TopBarLink>
					<TopBarLink to="/portfolio">Portfolio</TopBarLink>
					<TopBarLink to="/sections">Sections</TopBarLink>
				</HStack>
				<TopBarSpacer>
					<Button
						variant="outline"
						size="sm"
						colorScheme="whiteAlpha"
						color="white"
						onClick={() => {
							localStorage.removeItem(accessKeyStorageKey);
							window.location.reload();
						}}
					>
						Reset Access Key
					</Button>
				</TopBarSpacer>
			</Flex>
			{children}
		</Box>
	);
};

export default View;

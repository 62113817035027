import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { accessKeyStorageKey } from "../../constants/keys";

const AccessKeyPrompt: React.FC = ({ children, ...props }) => {
	const [promptIsOpen, setPromptIsOpen] = useState<boolean>(
		!localStorage.getItem(accessKeyStorageKey),
	);
	const [accessKeyInputValue, setAccessKeyInputValue] = useState("");

	const onClose = () => {
		setPromptIsOpen(false);
	};

	const accessKeyOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAccessKeyInputValue(e.target.value);
	};

	const onSubmit = () => {
		localStorage.setItem(accessKeyStorageKey, accessKeyInputValue);
		onClose();
	};

	return (
		<Modal isOpen={promptIsOpen} onClose={onClose} closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Access Key</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Please enter access key
					<Input value={accessKeyInputValue} onChange={accessKeyOnChange} />
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="blue" onClick={onSubmit}>
						Submit
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AccessKeyPrompt;

import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AccessKeyPrompt from "./components/domain/AccessKeyPrompt";
import LandingView from "./components/views/LandingView";
import { QueryClient, QueryClientProvider } from "react-query";
import SkillsView from "./components/views/SkillsView";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PortfolioView from "./components/views/PortfolioView";
import SectionsView from "./components/views/SectionsView";
import theme from "./config/theme";
import SkillEditView from "./components/views/SkillEditView";
import PortfolioItemEditView from "./components/views/PortfolioItemEditView";
import SectionEditView from "./components/views/SectionEditView";

const queryClient = new QueryClient();

export const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={theme}>
				<AccessKeyPrompt />
				<Router>
					<Switch>
						<Route exact path="/skills" component={SkillsView} />
						<Route exact path="/portfolio" component={PortfolioView} />
						<Route exact path="/sections" component={SectionsView} />
						<Route path="/edit/skills/:_id" component={SkillEditView} />
						<Route
							path="/edit/portfolio/:_id"
							component={PortfolioItemEditView}
						/>
						<Route path="/edit/sections/:_id" component={SectionEditView} />
						<Route path="/" component={LandingView} />
					</Switch>
				</Router>
			</ChakraProvider>
		</QueryClientProvider>
	);
	//TODO: (backend) Specific Server Error message for incorrect authentication key
	//TODO: Delete confirmation dialogue
};

import React from "react";
import PortfolioItemEditForm from "../domain/EditForms/PortfolioItemEditForm";
import ItemEditScreen from "../templates/ItemEditScreen";
import View from "../templates/View";

const PortfolioItemEditView: React.FC = ({ children, ...props }) => {
	return (
		<View title="Edit Skill">
			<ItemEditScreen itemType="portfolio" baseUrl="/portfolio">
				<PortfolioItemEditForm />
			</ItemEditScreen>
		</View>
	);
};

export default PortfolioItemEditView;

import { Textarea } from "@chakra-ui/react";
import React from "react";
import {
	useDefaultEditState,
	useEditForm,
} from "../../templates/ItemEditScreen";
import ItemEditTitleField from "../../templates/ItemEditTitleField";
import ItemEditInputField from "../../templates/ItemEditInputField";

const SectionEditForm: React.FC = ({ children, ...props }) => {
	const { editState, setEditState } = useEditForm();
	useDefaultEditState({ name: "New Section", body: "Section Body" });
	const { name, body } = editState;
	return (
		<>
			<ItemEditTitleField
				value={name}
				onChange={(e) => setEditState({ name: e.target.value })}
			/>
			<ItemEditInputField
				as={Textarea}
				label="Body"
				value={body}
				onChange={(e) => setEditState({ body: e.target.value })}
			/>
		</>
	);
};

export default SectionEditForm;
